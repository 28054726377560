<template>
	<div class="modal modal--feedback modal-feedback">
		<div class="modal-feedback__form">
			<form-feedback :feedback="feedback" />
		</div>
		<div class="modal-feedback__stats">
			<dl>
				<dt>Date Created</dt>
				<dd>{{ feedback.dateCreated | date('PPp') }}</dd>
				<dt>Created By</dt>
				<dd>{{ feedback.uid }}</dd>
				<dt>Date Modified</dt>
				<dd>{{ feedback.dateModified | date('PPp') }}</dd>
				<dt>Modified By</dt>
				<dd>{{ feedback.modifiedByName }}</dd>
			</dl>
		</div>
	</div>
</template>

<script>
import FormFeedback from '@/components/FormFeedback'
import { FeedbackStatus } from '@/lib/enums'

export default {
	name: 'ModalFeedback',
	components: {
		FormFeedback,
	},
	mounted() {
		if (this.$route.params.feedbackId) {
			this.$store.dispatch('feedback/get', this.$route.params.feedbackId)
				.then(res => {
					if (res.status == FeedbackStatus.Unread) {
						console.log('doUpdate', res, FeedbackStatus.Read)
						this.$store.dispatch('feedback/update', { feedbackId: res.feedbackId, data: {status: FeedbackStatus.Read} })

					}
				})
		}
	},
	destroyed() {
		this.$store.dispatch('feedback/unset')
	},
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		feedback() {
			return this.$store.getters['feedback/feedback']
		},
	},
}
</script>

<style scoped lang="scss">
.modal-feedback {
	display: grid;
	grid-template-columns: 2fr minmax(10rem, 1fr);
	min-width: 70vw;
	min-height: 50vh;
	width: 100%;

	@include modules.media-query-max-width('desktop-xs') {
		grid-template-columns: 1fr;
	}

	dl {
		@include modules.fontSize(14px);
		@include modules.gutter('grid-row-gap', 0.5);
		display: grid;
		grid-column-gap: 0;
		grid-template-columns: 1fr auto;
	}

	dd {
		margin: 0;
		text-align: right;
		padding: 0.5rem;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}

	dt {
		font-weight: 500;
		padding: 0.5rem;
		text-transform: uppercase;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}
}
</style>
