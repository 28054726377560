<template>
	<form class="form form--feedback form-feedback" @submit.prevent="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-2">
				<select-field class="form__field" label="Type" v-model="fields.type" :options="typeOptions" placeholder="Select Type..." :required="true" />
				<select-field class="form__field" label="Status" v-model="fields.status" :options="statusOptions" placeholder="Select Status..." :required="true" />
			</div>

			<dl>
				<dt>Type</dt>
				<dd>{{ fields.type | enums('FeedbackType', true) }}</dd>
				<dt>Release</dt>
				<dd>{{ fields.appVersion }}</dd>
				<dt>Brand</dt>
				<dd>{{ fields.brand }}</dd>
				<dt>Device</dt>
				<dd>{{ fields.device }}</dd>
				<dt>Platform</dt>
				<dd>{{ fields.platform | enums('FeedbackPlatform', true) }}</dd>
				<dt>Version</dt>
				<dd>{{ fields.version }}</dd>
			</dl>
			<div class="form-feedback__content">{{ fields.content }}</div>

			<div class="form__actions form__actions--horizontal">
				<mdc-button class="mdc-button--primary mdc-button--unelevated form__action" type="submit" :disabled="isLoading">
					{{ fields.feedbackId ? 'Update' : 'Create' }} Feedback
				</mdc-button>

				<mdc-button class="mdc-button--error form__action" type="button" :disabled="isLoading" @click="remove(fields)" v-if="fields.feedbackId && fields.status != 'removed'">
					Remove Feedback
				</mdc-button>
			</div>
		</div>
	</form>
</template>

<script>
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import MdcButton from '@/components/MdcButton'
import SelectField from '@/components/SelectField'
import TextArea from '@/components/TextArea'
import TextField from '@/components/TextField'
import { FeedbackStatus, FeedbackType, } from '@/lib/enums'

export default {
	name: 'FormFeedback',
	components: {
		Checkbox,
		LoadingSpinner,
		MdcButton,
		SelectField,
		TextArea,
		TextField,
	},
	props: {
		feedback: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
			status: 'active',
			locations: [],
		},
		fields: {},
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		error() {
			return this.$store.getters['misc/error']
		},
		statusOptions() {
			return FeedbackStatus.toDropdown()
		},
		typeOptions() {
			let types = []
			for (const k in FeedbackType) {
				types.push({
					label: k,
					value: FeedbackType[k],
				})
			}

			return types
		},
	},
	methods: {
		async submitForm(fields) {
			if (fields.feedbackId) {
				try {
					const { feedbackId } = fields
					await this.$store.dispatch('feedback/update', { feedbackId, data: fields, } )
					this.$notice(`Feedback updated.`)
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
					console.error(error)
				}
			} else {
				try {
					const res = await this.$store.dispatch('feedback/create', fields)
					this.$notice(`Feedback created.`)
					this.$router.push({ name: 'feedback', params: { feedbackId: res.feedbackId, }})
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
				}
			}
		},
		addLocation() {
			this.fields.locations.push({
				name: ``,
				address: ``,
				feedbackId: this.$route.params.feedbackId,
			})
		},
		providerChange(val) {
			try {
				this.fields.provider = JSON.stringify(JSON.parse(val), null, 4)
			} catch (error) {

			}
		}
	},
	watch: {
		feedback: {
			immediate: true,
			handler: function(props) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...props,
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">
@use '@material/icon-button/styles';

.form-feedback {
	dl {
		@include modules.gutter('grid-gap');
		display: grid;
		grid-template-columns: auto 1fr auto 1fr;

		dt {
			font-weight: 600;
		}
	}

	&__content {
		@include modules.gutter('padding');
		@include modules.gutter('margin-bottom');
		border: 1px solid modules.color_('border', 'dark');
		border-radius: 10px;
	}
}
</style>
