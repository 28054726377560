import { render, staticRenderFns } from "./FormFeedback.vue?vue&type=template&id=08b976a6&scoped=true&"
import script from "./FormFeedback.vue?vue&type=script&lang=js&"
export * from "./FormFeedback.vue?vue&type=script&lang=js&"
import style0 from "./FormFeedback.vue?vue&type=style&index=0&id=08b976a6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b976a6",
  null
  
)

export default component.exports